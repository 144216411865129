import { statusStyle, stringToCamelCase } from '../../../utils';
import { LABEL } from '../../../constants';
import {
    subTitleStyle,
    sh72,
    sh128,
    sh144,
    sh48,
    sh56,
    sh200,
    sh240,
    sh99,
    sh88,
    sh69,
    sh138,
    sh104,
    sh114,
    sh100,
    sh122,
} from '../../../styles';
import { useContext } from 'react';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';
import React from 'react';
import { DateHeader } from '../DateHeaderMenu';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../../routes';

interface IHistoryTableColumnsProps {
    filters: ISort_FilterInput;
    historyTab: IHistoryTab;
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    lastUpdatedDropdown: string;
    setFilters: (data: ISort_FilterInput) => void;
    setLastUpdatedDropdown: (value: string) => void;
    changeRequestColumn: boolean;
}

interface IColumnReturnType {
    columns: ITableColumn[];
}

export const useHistoryTableColumns = ({
    historyTab,
    lastUpdatedDropdown,
    setLastUpdatedDropdown,
    changeRequestColumn,
}: IHistoryTableColumnsProps): IColumnReturnType => {
    const { sortData, setSortData, dateFilter, setDateFilter, orderTypePills } = useContext(DashboardBranchContext);
    const _history = useHistory();

    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    const handleDateFilter = (item: string, value: string) => {
        setLastUpdatedDropdown(item);
        let currentFilter = 'lastUpdated';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.submittedOn:
                currentFilter = 'submittedOn';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setSortData([{ column: currentFilter, value: value }]);
    };

    const handleViewDetails = (item: IColumnItemAccordion) => {
        const data =
            item.rawData.transactionRef !== null
                ? {
                      transactionRef: item.rawData.transactionRef,
                      tab: 'history',
                      endpoint: 'branchDashboard',
                      url: _history.location.pathname,
                  }
                : {
                      orderNo: item.rawData.orderNo,
                      tab: 'history',
                      endpoint: 'branchDashboard',
                      url: _history.location.pathname,
                  };
        const _orderType = orderTypePills.tags.find((item) => item.active === true);
        _orderType?.title.toLowerCase() === 'transactions'
            ? _history.push(`${_history.location.pathname}/${ROUTES.orderSummary}`, {
                  orderNumber: item.rawData.orderNo,
                  ...data,
              })
            : _history.push(`${_history.location.pathname}/${ROUTES.orderSummaryChangeRequest}`, {
                  orderNumber: item.rawData.orderNo,
                  ...data,
              });
    };

    const dateHeaderFilter = (menu: IHeaderMenu) => {
        return (
            <DateHeader
                {...menu}
                dropdownData={[
                    lastUpdatedDropdown,
                    // eslint-disable-next-line react/prop-types
                    sortData[0].column === stringToCamelCase(lastUpdatedDropdown) ? sortData[0].value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };

    const columnsTransaction: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(lastUpdatedDropdown) }],
            viewStyle: {
                width: sh99,
            },
            title: lastUpdatedDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(lastUpdatedDropdown) ? 700 : 400,
            },
        },
        {
            key: [
                { key: 'orderNo', textStyle: { fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400 } },
                { key: 'transactionRef', textStyle: subTitleStyle },
            ],

            viewStyle: {
                width: sh88,
            },
            title: LABEL.transNo,
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('transactionRef'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh88,
            },
            icon: {
                name: handleSortIcon('transactionType'),
                size: '1rem',
            },
            title: LABEL.transTypes,
            onPressHeader: () => handleSort('transactionType'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionType' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'fundType', textStyle: { fontWeight: sortData[0].column === 'fundType' ? 700 : 400 } }],
            viewStyle: {
                width: sh69,
            },
            title: LABEL.products,
            onPressHeader: () => handleSort('fundType'),
            titleStyle: {
                fontWeight: sortData[0].column === 'fundType' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('fundType'),
                size: '1rem',
            },
        },
        {
            icon: {
                name: handleSortIcon('paymentMethod'),
                size: '1rem',
            },
            key: [
                { key: 'paymentMethod', textStyle: { fontWeight: sortData[0].column === 'paymentMethod' ? 700 : 400 } },
            ],
            viewStyle: {
                width: sh72,
            },
            title: LABEL.funding,
            onPressHeader: () => handleSort('paymentMethod'),
            titleStyle: {
                fontWeight: sortData[0].column === 'paymentMethod' ? 700 : 400,
            },
        },

        {
            key: [{ key: 'totalInvestment', textStyle: { fontWeight: 700 } }],
            viewStyle: {
                width: sh122,
            },
            title: LABEL.totalAmount,
            icon: {
                name: handleSortIcon('totalInvestment'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('totalInvestment'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalInvestment' ? 700 : 400,
            },
            textStyle: {
                wordBreak: 'break-word',
            },
        },
        {
            key: [{ key: 'totalUnits' }],
            viewStyle: {
                width: sh138,
            },
            title: LABEL.totalUnits,
            icon: {
                name: handleSortIcon('totalUnits'),
                size: '1rem',
            },

            onPressHeader: () => handleSort('totalUnits'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalUnits' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh104,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },

        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh114,
            },
            title: LABEL.status,
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'remarks' }],
            viewStyle: {
                width: sh72,
            },
            title: LABEL.remarks,
            customItem: true,
        },
        {
            key: [],
            viewStyle: {
                width: sh88,
            },
            itemIcon: {
                name: historyTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                historyTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'history-view-column',
        },
    ];

    const columnsChangeRequest: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(lastUpdatedDropdown) }],
            viewStyle: {
                width: sh128,
            },
            title: lastUpdatedDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(lastUpdatedDropdown) ? 700 : 400,
            },
            itemStyle: {
                position: 'relative',
            },
        },
        {
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
            },
            key: [{ key: 'orderNo' }, { key: 'transactionRef', textStyle: subTitleStyle }],

            viewStyle: {
                width: sh144,
            },
            title: LABEL.transNo,
            onPressHeader: () => handleSort('transactionRef'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.transTypes,
            icon: {
                name: 'arrow-down',
                size: 16,
                style: { opacity: '0.2' },
            },
        },
        {
            icon: {
                name: handleSortIcon('riskCategory'),
                size: '1rem',
            },
            key: [
                { key: 'riskCategory', textStyle: { fontWeight: sortData[0].column === 'riskCategory' ? 700 : 400 } },
            ],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.risk,
            onPressHeader: () => handleSort('riskCategory'),
            titleStyle: {
                fontWeight: sortData[0].column === 'riskCategory' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh240,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh200,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [{ key: 'remarks' }],
            viewStyle: {
                width: sh56,
            },
            title: LABEL.remarks,
            customItem: true,
        },
        {
            key: [],
            viewStyle: {
                width: sh48,
            },
            itemIcon: {
                name: historyTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                historyTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'history-view-column',
        },
    ];

    return { columns: changeRequestColumn ? columnsChangeRequest : columnsTransaction };
};
