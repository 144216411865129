/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { ceCheckerReviewRequest } from '../../_graphql/mutations/branchCE/ceCheckerReviewRequest/ceCheckerReviewRequest';
import { useComponentLoader } from '../../customHooks';
import { CustomSpacer, FlexedDiv } from '../../components';
import { dummyTransactionsSummary, LABEL, CE_DASHBOARD_LABELS, dummyDocumentSummary } from '../../constants';
import { handleOrderSummary, toTitleCase } from '../../utils';

import { initialProfile } from '../AccountDetails/Dummy';
import { dashboardBranch, viewBranch } from '../../routes';

import * as ROUTES from '../../routes';
import styled from 'styled-components';
import transactionStatusMutation from '../../_graphql/mutations/branchCE/afterReviewUpdate/transactionStatus';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import { DocumentSummary } from './DocumentSummary';
import { TrackingSummary } from './TrackingSummary';
import { getOrderSummaryV2 } from '../../_graphql/queries/common/getOrderSummaryV2';
import moment from 'moment';
import OrderTabTrx from './Tabs/Order/OrderTabTrx';
import AccountDetailsTab from './Tabs/Account/AccountTab';
import { getOrderModalContent, IgetOrderModalContentCb } from './Utils/getOrderModalContent';
import { getSummaryTitle } from './Utils/orderSummaryUtils';
import ProfileTab from './Tabs/Profile/ProfileTab';
import { getTransactionTracking } from '../../graphql/queries';

const modalMessages = {
    confirmation: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOrderDetailsTrxHandler = () => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { state } = useLocation<IOrderSummaryRouteProps>();
    const locationState = state;
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const branchId = sessionStorage.getItem('branchId');
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: ICeDashboard =
        parsedPermission.branch.grant !== undefined
            ? parsedPermission.branch.permission.ceTransaction
            : parsedPermission.hq.permission.ceDashboard;

    const { pendingTab } = parsedPermissionPrivilege;
    const previousPage = { currentTab: locationState ? locationState.tab ?? 'daily' : 'daily' };

    const transRef =
        locationState && locationState.transactionRef !== undefined
            ? locationState.transactionRef
            : locationState && locationState.orderNo && locationState.orderNo;
    const isRejectRequest: boolean =
        locationState && locationState.isRejectRequest !== undefined ? locationState.isRejectRequest : false;
    const previousUrl = locationState && locationState.url;

    //Data states
    const [orderSummary, setOrderSummary] = useState<IOrderSummaryResult>();
    const [summaryObjects, setSummaryObject] = useState<IOrderSummaryProps>({
        profile: initialProfile,
        transactionSummary: dummyTransactionsSummary,
        documentSummary: dummyDocumentSummary,
    });
    const [statusOverView, setStatusOverview] = useState<IStepperTimelineProps[]>([]);
    const [toggle, setToggle] = useState(true);
    const [rejectRemarks, setRejectRemarks] = useState<string>('');
    const [rejectReq, setRejectReq] = useState<boolean>(false);
    const [disableVerify, setDisableVerify] = useState<boolean>(false);
    const [fromInbox, setFromInbox] = useState<boolean>(false);
    //Data refs
    const activeTabRef = useRef<number>(1);

    const getActiveTab = (tab: number) => {
        activeTabRef.current = tab;
    };

    // Modal UI states
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    // Custom hooks
    const { loading, loadingHandler } = useComponentLoader();
    const manageAccountLinks = (): string => {
        return history.location.pathname.includes('/hq') ? ROUTES.hqAccountProfile : ROUTES.branchAccountProfile;
    };
    const manageInvestorProfileLinks = (): string => {
        return history.location.pathname.includes('/hq') ? ROUTES.hqInvestorProfile : ROUTES.branchInvestorProfile;
    };

    // Fn to call transactionStatus mutation
    const verifyTransactions = async (ref: string) => {
        handleConfirmModal();
        setDisableVerify(true);
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(transactionStatusMutation, {
                    input: {
                        transactionRef: ref,
                        action: 'Approve',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionStatus;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.transactionStatus.data;
            if (result.status === true) {
                setDisableVerify(false);
                setShowFeedbackModal((prevState) => !prevState);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setDisableVerify(false);
            if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Verify Transaction',
                    testId: 'ordersummary-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    // Fn to call transactionCheckerStatus mutation
    const reviewRequest = async (requestId: string, action: string, remarks?: string) => {
        setDisableVerify(true);
        handleConfirmModal();
        const payload =
            remarks !== undefined
                ? {
                      requestId: requestId,
                      action: action,
                      remarks: remarks,
                  }
                : {
                      requestId: requestId,
                      action: action,
                  };

        try {
            const response: any = await API.graphql(
                graphqlOperation(ceCheckerReviewRequest, {
                    input: payload,
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionCheckerStatus;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.transactionCheckerStatus.data;
            if (result.status) {
                setDisableVerify(false);
                setShowFeedbackModal((prevState) => !prevState);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            setDisableVerify(false);
            if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Review Request',
                    testId: 'ordersummary-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const fetchStatusOverView = async () => {
        loadingHandler();
        const _input = {
            orderNo: locationState.orderNo || locationState.orderNumber,
            transactionRef: locationState.transactionRef,
            filter: [{ column: 'level', value: 'all' }],
        };
        try {
            const response: any = await API.graphql(
                graphqlOperation(getTransactionTracking, {
                    input: _input,
                }),
            );
            const resultCheck = response.data.getTransactionTracking;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;
            const _overview: IStepperTimelineProps[] = result.trackingSummary.map(
                (trackingSummary: any, index: number) => {
                    return {
                        ...trackingSummary,
                        title: trackingSummary.level,
                        steps: trackingSummary.data.map((step: any, stepIndex: number) => {
                            return {
                                ...step,
                                finished: index !== 0 || stepIndex !== 0,
                                subText:
                                    step.createdOn !== null ? moment(step.createdOn, 'x').format('DD/MM/YYYY') : '-',
                                stepName: step.status,
                            };
                        }),
                    };
                },
            );
            setStatusOverview(_overview);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Status Overview',
                    testId: 'statusoverview-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    //Tab functionality
    const tabTitles: string[] = [];
    const ORDER_SUMMARY_LINKS: string[] = [];

    const tabs: JSX.Element[] = [];
    //Set document tab index depending on profile tab
    const _docTabIndex = summaryObjects.profile ? 4 : 3;
    //Profile tab is dynamic
    const isHybridProfile = () => {
        if (orderSummary?.profile && orderSummary?.profile.length < 2) return false;
        else {
            if (orderSummary?.profile) {
                let _ntbClient = -1;
                orderSummary.profile.map((item, index) => {
                    if (item.isEtb !== true && item !== null) _ntbClient = index;
                });
                return _ntbClient !== -1;
            }
        }
    };
    if (summaryObjects.profile !== undefined) {
        tabs.push(
            <ProfileTab
                profile={summaryObjects.profile}
                isEtb={orderSummary?.isEtb}
                isHybrid={isHybridProfile()}
                key={3}
            />,
        );
        tabTitles.push(LABEL.profile);
        ORDER_SUMMARY_LINKS.push('/profile');
    }

    //Account details tab checked first and position [1] key of 2
    if (summaryObjects.account !== undefined) {
        tabs.push(<AccountDetailsTab account={summaryObjects.account} key={2} />);
        tabTitles.push(LABEL.account);
        ORDER_SUMMARY_LINKS.push('/account');
    }

    //Document tab checked second and position [2] key of 3
    if (summaryObjects.documentSummary.softcopy.required || summaryObjects.documentSummary.hardcopy.required) {
        tabs.splice(
            _docTabIndex - 1,
            0,
            <DocumentSummary
                key={_docTabIndex}
                softCopy={summaryObjects.documentSummary.softcopy.documents.map((doc): ISection => {
                    return {
                        sectionTitle:
                            summaryObjects.documentSummary.softcopy.documents.length > 1
                                ? {
                                      title: doc.subHeader.toLowerCase().includes('principal')
                                          ? 'Principal Holder'
                                          : 'Joint Holder',
                                      titleIcon: doc.subHeader.toLowerCase().includes('principal')
                                          ? 'single-avatar'
                                          : 'joint-avatar',
                                      badgeLabel: doc.mainHeader,
                                      badgeColor: '#4D4D4D',
                                      badgeStyle: {
                                          border: 'none',
                                          fontSize: '0.75rem',
                                          lineHeight: '1rem',
                                      },
                                  }
                                : undefined,
                        data: doc.documents.map((docs): IDataWithLabel => {
                            return {
                                label: docs.title,
                                onClickData: docs.url,
                                data: docs.name,
                                dataIcon: docs.label == 'form' ? 'file' : docs.url != '-' ? 'Vector' : '',
                            };
                        }),
                    };
                })}
                physicalCopy={summaryObjects.documentSummary.hardcopy.utmcDocs?.map((doc): ISection => {
                    return {
                        sectionTitle: { title: toTitleCase(doc?.mainHeader), titleIcon: 'fund-house' },
                        data: doc?.documents.map((docs: any) => {
                            return {
                                label: docs.title,
                                onClickData: docs.url,
                                data: docs.name,
                                dataIcon: docs.label == 'form' ? 'file' : docs.url != '-' ? 'Vector' : '',
                            };
                        }),
                    };
                })}
                accDocs={summaryObjects.documentSummary.hardcopy.accDocs.map((acc) => {
                    return {
                        mainHeader: acc.mainHeader,
                        subHeader: acc.subHeader,
                        documents: acc.documents.map((docs) => {
                            return {
                                label: docs.title,
                                onClickData: docs.url,
                                data: docs.name,
                                dataIcon: docs.label == 'form' ? 'file' : docs.url != '-' ? 'Vector' : '',
                            };
                        }),
                    };
                })}
                noPhysical={!summaryObjects.documentSummary.hardcopy.required}
                noSoftCopy={!summaryObjects.documentSummary.softcopy.required}
                jointAcc={summaryObjects.documentSummary.accountType !== 'Individual'}
                noInfoImage="Empty_document_info"
            />,
        );
        tabTitles.splice(_docTabIndex - 1, 0, LABEL.document);
        ORDER_SUMMARY_LINKS.splice(_docTabIndex - 1, 0, '/document');
    }
    //Order details tab position [0] key of 1
    tabs.unshift(
        <OrderTabTrx
            branch={orderSummary?.transactionDetails[0].kibProcessingBranch}
            transactions={summaryObjects.transactionSummary as ITransactionsSummaryTrx}
            key={1}
            fromInbox={fromInbox}
        />,
    );

    tabTitles.unshift(getSummaryTitle(locationState, orderSummary?.status));
    ORDER_SUMMARY_LINKS.unshift('');
    //Tracking tab position [3] key of last index
    tabs.push(<TrackingSummary key={tabs.length + 1} orderNo={orderSummary?.orderNo} />);
    tabTitles.push(LABEL.tracking);
    ORDER_SUMMARY_LINKS.push('/track');

    const handleAlert = () => {
        setToggle(!toggle);
    };

    // Fn to handle verify order action and set modal values
    const handleVerifyOrder = () => {
        // set confirmation modal data
        modalMessages.confirmation.heading = (
            <span>
                {CE_DASHBOARD_LABELS.DAILY.verifyThisTransaction} <br></br> {orderSummary?.orderNo}
                {orderSummary?.transactionRef}
            </span>
        ) as unknown as string;

        modalMessages.confirmation.content = (
            <FlexedDiv direction="column">
                <Subtitle>
                    {locationState.verifyOnly
                        ? CE_DASHBOARD_LABELS.DAILY.verifyOnlyThisTransactionMessage
                        : pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer
                        ? CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessage
                        : CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessageCr}
                </Subtitle>
                <CustomSpacer space={'.5rem'} />
                <Subtitle>
                    {pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer
                        ? CE_DASHBOARD_LABELS.DAILY.yesOrNoVerify
                        : CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}
                </Subtitle>
            </FlexedDiv>
        );
        modalMessages.confirmation.type = 'verify';

        if (
            pendingTab.reviewApproval.canApproveVerify === LABEL.autoAuthorizer ||
            pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer
        ) {
            // set feedback modal data
            modalMessages.feedback.heading = 'Transactions verified successfully';
            modalMessages.feedback.content = (
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                    <Bold>
                        {orderSummary?.orderNo}
                        {orderSummary?.transactionRef}
                    </Bold>
                    &nbsp; has been verified and <br /> adviser will be notified.
                </p>
            );
            modalMessages.feedback.icon = 'trx-verification-success';
        } else {
            // set feedback modal data
            modalMessages.feedback.heading = CE_DASHBOARD_LABELS.DAILY.submitTransactionRequest;
            modalMessages.feedback.content = (
                <p
                    style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px', marginTop: '-0.5rem' }}
                    dangerouslySetInnerHTML={{
                        __html: `This request has been submitted and<br /> pending for review by checker.`,
                    }}
                />
            );
            modalMessages.feedback.icon = 'trx-verification-request-submit';
        }

        setShowConfirmationModal((prevState) => !prevState);
    };

    // Fn to handle order approval requests and set modal values
    const handleApproveRequest = () => {
        const { modalContent }: IgetOrderModalContentCb = getOrderModalContent(
            locationState.approvalType ? locationState.approvalType : '',
        );

        // set confirmation modal data
        modalMessages.confirmation.heading = modalContent[0].heading;
        modalMessages.confirmation.content = modalContent[0].content;
        modalMessages.confirmation.type = modalContent[0].type;
        // set feedback modal data
        modalMessages.feedback.heading = modalContent[1].heading;
        modalMessages.feedback.content = (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <Subtitle>
                    Request for&nbsp;
                    <Bold>
                        {orderSummary?.orderNo}
                        {orderSummary?.transactionRef}
                    </Bold>
                    &nbsp;has been approved.
                    <br /> Maker will be notified. Adviser will be notified once all transactions in this order has been
                    verified.
                </Subtitle>
            </FlexedDiv>
        );
        modalMessages.feedback.icon = 'trx-verification-request-approve';
        handleConfirmModal();
    };

    const handleReviewRerouteRejectRequest = () => {
        const reviewRerouteRejectRequestRouteState = {
            ...locationState.rawData,
            transactionType: orderSummary?.transactionType,
        };

        switch (locationState.approvalType) {
            case 'reroute':
                if (history.location.pathname.includes('all-branches'))
                    history.push({
                        pathname: `${ROUTES.hqAllSubmissions}/${ROUTES.rerouteTransaction}`,
                        state: { data: reviewRerouteRejectRequestRouteState, isReject: false },
                    });
                else if (history.location.pathname.includes('hq/inbox'))
                    history.push({
                        pathname: `${ROUTES.hqInbox}/${ROUTES.rerouteTransaction}`,
                        state: { data: reviewRerouteRejectRequestRouteState, isReject: false },
                    });
                else if (history.location.pathname.includes('view-branch'))
                    history.push({
                        pathname: `${ROUTES.viewBranch}/${ROUTES.rerouteTransaction}`,
                        state: { data: reviewRerouteRejectRequestRouteState, isReject: false },
                    });
                else
                    history.push({
                        pathname: `/branch/dashboard/${ROUTES.rerouteTransaction}`,
                        state: { data: reviewRerouteRejectRequestRouteState, isReject: false },
                    });
                break;
            case 'reject':
                if (history.location.pathname.includes('all-branches'))
                    history.push({
                        pathname: `${ROUTES.hqAllSubmissions}/${ROUTES.rejectTransaction}`,
                        state: { data: reviewRerouteRejectRequestRouteState, isReject: false },
                    });
                else if (history.location.pathname.includes('hq/inbox'))
                    history.push({
                        pathname: `${ROUTES.hqInbox}/${ROUTES.rejectTransaction}`,
                        state: { data: reviewRerouteRejectRequestRouteState, isReject: false },
                    });
                else if (history.location.pathname.includes('view-branch'))
                    history.push({
                        pathname: `${ROUTES.viewBranch}/${ROUTES.rejectTransaction}`,
                        state: { data: reviewRerouteRejectRequestRouteState, isReject: false },
                    });
                else
                    history.push({
                        pathname: `/branch/dashboard/${ROUTES.rejectTransaction}`,
                        state: { data: reviewRerouteRejectRequestRouteState, isReject: false },
                    });
                break;
            default:
                break;
        }
    };
    // Fn to handle reject order verification requests and set modal values
    const rejectRequest = () => {
        // set feedback modal data
        modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.transactionRequestReject}`;
        modalMessages.feedback.content = (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <Subtitle>
                    Request for&nbsp;
                    <Bold>
                        {orderSummary?.orderNo}
                        {orderSummary?.transactionRef}
                    </Bold>
                    &nbsp;has been rejected. <br />
                    Maker will be notified.
                </Subtitle>
            </FlexedDiv>
        );
        modalMessages.feedback.icon = 'trx-verification-request-approve';
        orderSummary?.requestId !== undefined
            ? reviewRequest(orderSummary?.requestId.toString(), 'reject', rejectRemarks)
            : null;
    };
    const rerouteOrder = () => {
        const data: ITableData = {
            ['orderNo']: orderSummary?.orderNo,
            ['transactionRef']: orderSummary?.transactionRef,
            transactionType: orderSummary?.transactionType,
        };
        const innerRoutes = ['profile', 'document', 'track'];
        const innerRouteFound = innerRoutes.map((_route) => {
            return history.location.pathname.includes(_route);
        });
        if (innerRouteFound.filter(Boolean).length !== 0) {
            const tempArray = history.location.pathname.split('/');
            tempArray.splice(tempArray.length - 2, 2);
            const redirectLocation = tempArray.join('/');
            history.push({ pathname: `${redirectLocation}/${ROUTES.rerouteTransaction}`, state: { data: data } });
        } else history.push({ pathname: ROUTES.rerouteTransaction, state: { data: data } });
    };
    const rejectOrder = () => {
        const rejectOrderState: ITableData = {
            ['orderNo']: orderSummary?.orderNo,
            ['transactionRef']: orderSummary?.transactionRef,
            transactionType: orderSummary?.transactionType,
        };
        const innerRoutes = ['profile', 'document', 'track'];
        const innerRouteFound = innerRoutes.map((_route) => {
            return history.location.pathname.includes(_route);
        });
        if (innerRouteFound.filter(Boolean).length !== 0) {
            const tempArray = history.location.pathname.split('/');
            tempArray.splice(tempArray.length - 2, 2);
            const redirectLocation = tempArray.join('/');
            history.push({
                pathname: `${redirectLocation}/${ROUTES.rejectTransaction}`,
                state: { data: rejectOrderState },
            });
        } else history.push({ pathname: ROUTES.rejectTransaction, state: { data: rejectOrderState } });
    };

    //Fn to handle investor profile btn
    const handleInvestorProfileBtn = (type: string) => {
        const routeState =
            type.toLowerCase() === 'joint'
                ? {
                      idNumber: summaryObjects.transactionSummary.idNumberInvestorJoint,
                      accountType: type,
                  }
                : {
                      idNumber: summaryObjects.transactionSummary.idNumberInvestor,
                      accountType: type,
                  };
        history.push({
            pathname: manageInvestorProfileLinks(),
            state: routeState,
        });
    };

    //Fn to generate principal joint profile btns
    const generateHeaderButtons = (type: string, isETB: boolean | undefined): ICustomButton[] => {
        const headerButtons: ICustomButton[] = [];
        if (type.toLowerCase() === 'individual' && isETB) {
            headerButtons.push({
                style: { width: '134px', height: '32px', borderColor: '#002043', lineHeight: '1', border: 'none' },
                children: 'Investor Profile',
                onClick: () => handleInvestorProfileBtn('individual'),
            });
        } else if (type.toLowerCase() === 'joint' && isETB) {
            headerButtons.push({
                style: {
                    width: '134px',
                    height: '32px',
                    borderColor: '#002043',
                    lineHeight: '1',
                    border: 'none',
                    borderRight: '1px solid #002043',
                    borderRadius: '0px',
                },
                children: 'Principal Profile',
                onClick: () => handleInvestorProfileBtn('individual'),
            });
            headerButtons.push({
                style: {
                    width: '134px',
                    height: '32px',
                    borderColor: '#002043',
                    lineHeight: '1',
                    border: 'none',
                    borderRadius: '0px',
                },
                children: 'Joint Profile',
                onClick: () => handleInvestorProfileBtn('joint'),
            });
        } else if (
            type.toLowerCase() === 'joint' &&
            isETB === false &&
            orderSummary?.transactionType.toLowerCase() === 'sales-ao'
        ) {
            const investorArray = orderSummary?.profile.map((item, index) => {
                return item.isEtb === false ? index : null;
            });
            const etbIndex = investorArray.findIndex((item) => item === null);
            if (etbIndex !== -1) {
                orderSummary?.profile[etbIndex].principal
                    ? headerButtons.push({
                          style: {
                              width: '134px',
                              height: '32px',
                              borderColor: '#002043',
                              lineHeight: '1',
                              border: 'none',
                              borderRadius: '0px',
                          },
                          children: 'Principal Profile',
                          onClick: () => handleInvestorProfileBtn('individual'),
                      })
                    : headerButtons.push({
                          style: {
                              width: '134px',
                              height: '32px',
                              borderColor: '#002043',
                              lineHeight: '1',
                              border: 'none',
                              borderRadius: '0px',
                          },
                          children: 'Joint Profile',
                          onClick: () => handleInvestorProfileBtn('joint'),
                      });
            }
        }

        return headerButtons;
    };

    // Fn to toggle confirmation modal
    const handleConfirmModal = () => {
        setShowConfirmationModal((prevState) => !prevState);
    };

    const handleModal = () => {
        setShowFeedbackModal((prevState) => !prevState);
        if (history.location.pathname.includes(ROUTES.hqAllSubmissions)) {
            previousUrl && history.push(previousUrl, previousPage);
        } else if (locationState.fromInbox) {
            history.location.pathname.includes('/hq/') ? history.push(ROUTES.hqAllSubmissions) : null;
            history.location.pathname.includes('/branch/') ? history.push(ROUTES.dashboardBranch) : null;
        } else {
            if (branchId !== null) {
                history.push(viewBranch, previousPage);
            } else {
                history.push(dashboardBranch, previousPage);
            }
        }
    };

    const handleBack = () => {
        if (fromInbox === true) {
            history.goBack();
        } else if (previousUrl) {
            history.push(previousUrl, previousPage);
        } else {
            if (branchId !== null) {
                history.push(viewBranch, previousPage);
            } else {
                history.push(dashboardBranch, previousPage);
            }
        }
    };

    //fn to change route to expand status overview
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleViewStatusOverview = (_key: number) => {
        history.push({ pathname: path + ORDER_SUMMARY_LINKS.at(-1), state: locationState });
    };

    //fn to hanlde reject cancel
    const handleRejectCancel = () => {
        setRejectReq(false);
        setRejectRemarks('');
        handleBack();
    };
    /**
     * @description returns true if there is valid permission to review the action type is present
     * @param actionType can take values 'approve','reroute' and 'reject'
     */
    const isPermissionValidToReview = (actionType: string | undefined): boolean => {
        switch (actionType) {
            case 'approve':
                return (
                    pendingTab.reviewApproval.canApproveVerify === LABEL.checker ||
                    pendingTab.reviewApproval.canApproveVerify === LABEL.autoAuthorizer
                );
            case 'reroute':
                return (
                    pendingTab.reviewApproval.canApproveReRoute === LABEL.checker ||
                    pendingTab.reviewApproval.canApproveReRoute === LABEL.autoAuthorizer
                );
            case 'reject':
                return (
                    pendingTab.reviewApproval.canApproveReject === LABEL.checker ||
                    pendingTab.reviewApproval.canApproveReject === LABEL.autoAuthorizer
                );
            default:
                return false;
        }
    };
    //Fn to call OrderSummaryInputV2 query
    const fetchOrderSummary = async () => {
        loadingHandler();
        try {
            let inputObj = {};
            if (
                fromInbox === true &&
                locationState.isRequestFromInbox === true &&
                locationState.requestIdFromInbox !== undefined
            ) {
                inputObj = {
                    transactionRef: transRef,
                    requestId: locationState.requestIdFromInbox,
                    tab: locationState.tab,
                    endpoint: locationState.endpoint,
                };
            } else {
                if (locationState.transactionRef !== undefined)
                    inputObj = {
                        transactionRef: transRef,
                        tab: locationState.tab,
                        endpoint: locationState.endpoint,
                    };
                else inputObj = { orderNumber: transRef, tab: locationState.tab, endpoint: locationState.endpoint };
            }
            const response: any = await API.graphql(
                graphqlOperation(getOrderSummaryV2, {
                    input: {
                        ...inputObj,
                    },
                }),
                idTokenHeader,
            );
            const orderSummaryCheck: StandardResponse = response.data.getOrderSummaryV2;
            if (orderSummaryCheck.error !== null) throw orderSummaryCheck.error;
            const { result } = response.data.getOrderSummaryV2.data;
            setOrderSummary(result);
            setSummaryObject(handleOrderSummary(result, manageAccountLinks(), locationState));
            loadingHandler();
        } catch (error) {
            console.log(error);
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Order Summary',
                    testId: 'ordersummary-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    useEffect(() => {
        fetchOrderSummary();
        fetchStatusOverView();
        locationState && locationState.fromInbox !== undefined && locationState.fromInbox !== null
            ? setFromInbox(locationState.fromInbox)
            : setFromInbox(false);
        if (isRejectRequest) setRejectReq(true);
    }, []);
    return {
        disableVerify,
        handleAlert,
        handleApproveRequest,
        handleBack,
        handleConfirmModal,
        handleModal,
        handleRejectCancel,
        handleVerifyOrder,
        handleViewStatusOverview,
        loading,
        locationState,
        modalMessages,
        ORDER_SUMMARY_LINKS,
        orderSummary,
        pendingTab,
        rejectOrder,
        rejectRemarks,
        rejectReq,
        rejectRequest,
        rerouteOrder,
        reviewRequest,
        setRejectRemarks,
        setRejectReq,
        showConfirmationModal,
        showFeedbackModal,
        statusOverView,
        tabs,
        tabTitles,
        toggle,
        verifyTransactions,
        generateHeaderButtons,
        activeTabRef,
        getActiveTab,
        isPermissionValidToReview,
        handleReviewRerouteRejectRequest,
    };
};

const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;

    color: #333333;
`;
const Bold = styled.span`
    font-weight: 700;
`;
